<template>
  <DsPageContent>
    <!-- Heading -->
    <DsHero
      v-if="content.headingTitle"
      :title="content.headingTitle"
      theme="light"
      alignment="center"
      with-title-size-large
    />
    <DsSectionImage v-if="content.headingMedia" theme-variant="light-grey">
      <div class="ds-grid">
        <div class="ds-grid__col">
          <DsScreenWrapper v-if="content.headingMedia" :ready="hydrateReady">
            <DsVideoPlayer
              :aria-label-play="labels.play"
              :aria-label-close="labels.close"
              v-bind="content.headingMedia"
              :screen="screen"
            />
          </DsScreenWrapper>
        </div>
      </div>
    </DsSectionImage>
    <!-- Intro -->
    <DsSection v-if="content.introTitle" theme="grey">
      <div class="ds-grid">
        <h2 class="ds-heading ds-grid__col ds-grid__col--12 ds-md-grid__col--4 ds-margin-bottom-24">
          {{ content.introTitle }}
        </h2>
        <div class="ds-grid__col ds-md-grid__col--offset-1 ds-grid__col--12 ds-md-grid__col--7">
          <RichText v-if="content.introDescription" :data="content.introDescription" />
          <DsLink
            v-if="content.introLink"
            class="ds-margin-top-24"
            :url="content.introLink.url"
            :open-in-new-window="content.introLink.openInNewWindow"
            :nuxt="content.introLink.nuxt"
            :content="content.introLink.title"
            :icon-right="{ category: 'arrows', name: 'chevron-right-small' }"
          />
        </div>
      </div>
    </DsSection>
    <!-- Company formula -->
    <CareersCompanyFormula
      v-if="content.companyFormulaItems && content.companyFormulaItems.length"
      :title="content.companyFormulaTitle"
      :description="content.companyFormulaDescription"
      :items="content.companyFormulaItems"
    />
    <!-- Quote -->
    <CareersQuoteBlock v-if="content.quote" :quote="content.quote" />
    <!-- Career types -->
    <section v-if="content.careerTypes" class="ds-background-color-grey-100">
      <DsImageGallery
        class="ds-padding-y-48 ds-md-padding-y-96"
        :screen="screen"
        :items="content.careerTypes.items.length"
        :title="content.careerTypes.title"
        :description="content.careerTypes.description"
        :width="{ small: 272, large: 576 }"
      >
        <template #default="{ index }">
          <DsCard
            :image="content.careerTypes.items[index - 1].image"
            :title="content.careerTypes.items[index - 1].title"
            :text="content.careerTypes.items[index - 1].description"
            :link="content.careerTypes.items[index - 1].link"
            aspect-ratio="16/9"
            with-content-padding
            class="ds-background-color-white ds-height-full"
          />
        </template>
      </DsImageGallery>
    </section>
    <!-- Benefits -->
    <DsSection v-if="content.benefitsItems">
      <div class="ds-grid">
        <div class="ds-grid__col">
          <h2 class="ds-heading" v-text="content.benefitsTitle" />
        </div>
      </div>
      <DsGrid
        :column-width="{ small: 12, medium: 4, large: 4 }"
        :items="content.benefitsItems"
        class="ds-margin-top-48"
      >
        <template #default="{ item }">
          <DsCardTextIcon :icon="item.icon" :title="item.title" icon-frame-size="24">
            <RichText :data="item.description" />
          </DsCardTextIcon>
        </template>
      </DsGrid>
    </DsSection>
    <!-- Locations -->
    <DsScreenWrapper v-if="content.locationsMedia" :ready="hydrateReady">
      <div class="ds-grid">
        <div class="ds-grid__col">
          <DsSectionImage
            v-if="content.locationsMedia"
            theme-variant="light-grey"
            :screen="screen"
            :image="content.locationsMedia"
          />
        </div>
      </div>
    </DsScreenWrapper>
    <DsSection v-if="locationsData" theme="grey">
      <CareersBlockOffices :title="content.locationsTitle" :locations="locationsData" />
    </DsSection>
    <DsSection>
      <div class="ds-grid">
        <div class="ds-grid__col">
          <CareersVacanciesList with-limit sort-by-latest as-widget />
        </div>
      </div>
    </DsSection>
  </DsPageContent>
</template>

<script setup>
import {
  DsLink,
  DsHero,
  DsSection,
  DsSectionImage,
  DsImageGallery,
  DsCard,
  DsGrid,
  DsCardTextIcon,
  DsScreenWrapper,
  DsVideoPlayer,
  DsPageContent
} from 'awds'
import { queryPageCareers, queryPageCareersLocationsLite } from '../data-layer/queries'

import { callWithNuxt } from '#app/nuxt'

const { locale } = useI18n()
const { screen } = useScreen()
const { hydrateReady } = useHydrateHook()
const app = useNuxtApp()

const { useAsyncDataWithQuery, useRelatedPagesAsyncDataWithQuery } = useCareersPageData()
const { fetchGlobalDataForContentType, labels, localeGlobalContentData } = useCareersGlobalData()

// Initial global content data fetch
await fetchGlobalDataForContentType('PageCareers', locale.value)

const { data } = await useAsyncData('careers-main-' + locale.value, async () => {
  // Cities, FormsLabels
  const [page, locations] = await Promise.all([
    useAsyncDataWithQuery(queryPageCareers, { pageSlug: 'careers' }),
    useRelatedPagesAsyncDataWithQuery(queryPageCareersLocationsLite, { skipMapping: true })
  ])

  return {
    pageData: page.pageData,
    locationsData: locations
  }
})

// Computed
const content = computed(() => {
  return data.value?.pageData?.content || {}
})

const locationsData = computed(() => {
  return data.value?.locationsData?.items || []
})

callWithNuxt(app, usePageSEO, [data])
</script>
